import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../css/pages/alwaysapril.scss"

const AlwaysAprilPage = () => {
  const YOUTUBE_ID = "K5P_xGMKlzc"
  return (
    <Layout language="en">
      <SEO title="Always April" description="Always April" />
      <div className="aa-23">
        <div className="aa-23__section">
          <div className="aa-23__container">
            <div className="aa-23__1x1-grid">
              <img
                src="https://res.cloudinary.com/nuvolum/image/upload/f_auto,q_99/v1/Programs/2023-always-april-logo"
                alt="always april logo"
              />
            </div>
          </div>
        </div>

        <div className="aa-23__hero">
          <div className="aa-23__container">
            <div className="aa-23__1x1-grid">
              <div>
                <p>
                  April is Oral Cancer Awareness Month. Always April is a
                  national partnership of oral surgery practices that aims to
                  bring oral cancer awareness into a year-round discussion.
                </p>
                <p>
                  At Golden Foothills Oral, Facial, & Dental Implant Surgery, we
                  look for signs of oral cancer during every patient exam. The
                  earlier oral cancer is detected and treated, the better the
                  outcome. Schedule your free oral cancer screening today!
                </p>
              </div>
              <div className="p-relative">
                <img
                  className="aa-23__hero-img"
                  src="https://res.cloudinary.com/nuvolum/image/upload/f_auto,q_99/v1/Programs/always-april-smile"
                  alt="smiling woman"
                />
                <div className="aa-23__mobile-box"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="aa-23__section">
          <div className="aa-23__container">
            <h2>The facts…</h2>
            <ul className="aa-23__1x1-grid">
              <li>
                One person dies from oral cancer every 49 minutes in the United
                States.
              </li>
              <li>
                Oral cancer is one of the most common, yet most preventable,
                cancers. When detected early, the 5-year relative survival rate
                is approximately 81%.
              </li>
              <li>
                Ultraviolet (UV) light (typically sunlight) can cause cancers of
                the lip and are most common in people who work or spend extended
                periods of time outdoors.
              </li>
              <li>
                Several studies have found that a diet low in fruits and
                vegetables is linked with an increased risk of oral cancer.
              </li>
              <li>Oral cancer is rising in low-risk groups.</li>
            </ul>
          </div>
        </div>

        <div className="aa-23__section pink-section p-relative">
          <div className="aa-23__1x1-grid v-center">
            <div>
              <img
                className="aa-23__glove-img"
                src="https://res.cloudinary.com/nuvolum/image/upload/f_auto,q_99/v1/Programs/2023-always-april-ribbon-combined"
                alt="hand holding always april logo"
              />
            </div>
            <div className="aa-23__expect">
              <h2>What to expect during an oral cancer screening</h2>
              <p>
                There is no need to do anything to prepare for a standard oral
                cancer screening. During the screening, an oral care specialist
                will look over the inside of your mouth to check for red or
                white patches or mouth sores. They will also feel the tissues in
                your mouth to check for lumps or other abnormalities and may
                examine your throat and neck for lumps.
              </p>
            </div>
          </div>
        </div>

        <div className="aa-23__section">
          <div className="aa-23__container">
            <h2 style={{ marginBottom: "30px" }}>
              Screenings take just a few minutes.
              <br />
              Schedule your FREE appointment today.
            </h2>
            <div className="aa-23__1x1-grid">
              <div>
                <p>
                  <b>Date</b>:
                  <br />
                  Wednesday April 23
                </p>
                <p>
                  <b>Time</b>: <br />
                  4:00 PM
                </p>
                <p>
                  <b>Locations</b>:
                </p>
                <p className="mt-0">
                  <a
                    href="https://maps.app.goo.gl/wXsDczcSzB5kdTd46"
                    target="_blank"
                    title="Get directions">
                    4901 Golden Foothill Pkwy
                    <br />
                    El Dorado Hills, CA 95762
                  </a>
                </p>
                <a
                  href="https://calendly.com/dymd/golden-foothills"
                  target="_blank"
                  className="aa-23__button">
                  Schedule Here
                </a>
              </div>
              <div>
                {YOUTUBE_ID && (
                  <>
                    <div className="aa-23__youtube">
                      <iframe
                        className="aa-23__iframe"
                        src={`https://www.youtube.com/embed/${YOUTUBE_ID}?modestbranding=1&rel=0&cc_load_policy=1`}
                        allow="accelerometer; autoplay; encrypted-media; gyroscope;"
                        allowFullScreen
                        frameborder="0"></iframe>
                    </div>
                    <p>
                      Watch this video from our national Always April partners
                      to learn more about oral cancer awareness and the
                      importance of early detection and screenings.
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="aa-23__disclaimer">
          <div className="aa-23__container">
            <p>
              <i>
                *Advanced appointments requested. Local health guidelines for
                COVID-19 will be followed and masks are required as we look to
                provide a safe and healthy environment for you, our patients,
                staff, and doctors.
              </i>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AlwaysAprilPage
